import styled from 'styled-components/macro';
import { useRef, useState } from 'react';
import { Contact } from './Contact';
import { UnconvincedWriter } from './UnconvincedWriter';

export const About2Contact: React.FC = () => {
    const [isAtTitle, setIsAtTitle] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const top = ref.current ? ref.current.getBoundingClientRect().top : 21;
    const isThere = top <= 20;

    return (
        <Container ref={ref}>
            <Header>
                <UnconvincedWriter
                    lines={['ABOUT', 'CONTACT']}
                    pause={!isAtTitle}
                    hideCursor={!isAtTitle}
                    startWithLine
                    onCompleted={() => setShowContacts(true)}
                    deleteSpeed={50}
                    writeSpeed={100}
                />
            </Header>
            <TextBox>
                <UnconvincedWriter
                    lines={[
                        'WIR SIND EIN KREATIVES TEAM MIT MOTIVIERTEN ',
                        'EGAL, OB EVENTS, VIDEO PRODUKTION ODER ',
                        'EINFACH BUCHEN UND SELBST ERLEBEN.',
                    ]}
                    pause={!isThere}
                    onCompleted={() => setIsAtTitle(true)}
                />
            </TextBox>
            <ContactContainer isVisible={showContacts}>
                <Contact />
            </ContactContainer>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    height: 100svh;
    padding: 5vw;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    flex-direction: column;
`;
const Header = styled.h1`
    margin-top: 10vh;
    font-size: 4vh;
`;

const TextBox = styled.div`
    font-size: 2vh;
`;

const ContactContainer = styled.div<{ isVisible?: boolean }>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s;
    pointer-events: ${({ isVisible }) => (isVisible ? '' : 'none')};
    flex: 1;
`;
