import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindow } from 'hooks';
import { Color } from 'SharedStyles.styles';
import styled from 'styled-components/macro';
import { Preview } from './Preview';

type Props = {
    caseData: any;
    slidesToLeft: boolean;
};

export const Showcase: React.FC<Props> = ({ caseData, slidesToLeft }) => {
    const [isVisibleBox, setIsVisibleBox] = useState(false);
    const [x, setX] = useState(0);
    const navigate = useNavigate();
    const { scrollTop, windowHeight } = useWindow();
    const playZoneStart = useRef(0);
    const handleIsPlaying = (isPlaying: boolean) => {
        if (!isVisibleBox && isPlaying) {
            setIsVisibleBox(true);
            playZoneStart.current = scrollTop;
        }
    };

    useEffect(() => {
        if (isVisibleBox) {
            const relScrolled = Math.max(Math.min((scrollTop - playZoneStart.current) / windowHeight, 1), 0);
            const dir = slidesToLeft ? 1 : -1;
            setX(dir * 10 * relScrolled);
        }
    }, [scrollTop]);

    return (
        <Container onClick={() => navigate(`./work/${caseData.slug}`)}>
            <PreviewSlider>
                <PreviewContainer style={{ transform: `translateX(${x}vw)` }} isRight={slidesToLeft}>
                    <Preview caseData={caseData} playMode="onScrollTop" onIsPlaying={handleIsPlaying} playZoneOffset={windowHeight / 4} />
                </PreviewContainer>
            </PreviewSlider>
            <TextBox style={isVisibleBox ? { transform: 'initial', opacity: 1 } : {}}>
                <Title>{caseData.title || ''}</Title>
                <Client>{caseData.client || ''}</Client>
            </TextBox>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    position: relative;
    cursor: pointer;
`;

const PreviewSlider = styled.div`
    height: 80vh;
    width: 100vw;
    background-color: ${Color.black};
    overflow: hidden;
`;
const PreviewContainer = styled.div<{ isRight?: boolean }>`
    height: 100%;
    width: 110vw;
    position: relative;
    right: ${({ isRight }) => (isRight ? 10 : 0)}vw;
`;

const TextBox = styled.div`
    width: 100vw;
    opacity: 0;
    transform: translateY(10vh);
    transition: opacity 0.7s, transform 0.7s;
    bottom: 5vh;
    position: absolute;
    padding: 12px;
    font-size: 3vh;
    filter: drop-shadow(${Color.black} -1px 2px 2px);
    box-sizing: border-box;
`;

const Title = styled.h2`
    color: ${Color.white};
    position: relative;
    margin: 15px;
`;

const Client = styled.h3`
    margin: 10px 15px;
    z-index: 1;
    position: relative;
    color: ${Color.primary2};
`;
