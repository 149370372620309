import styled from 'styled-components/macro';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useLoadState } from 'hooks';
import { useEffect, useMemo } from 'react';
import { LoadScreen, Navigator } from 'components';
import { Color } from 'SharedStyles.styles';

export const BasePage: React.FC = () => {
    const { isLoadingGlobal } = useLoadState();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const queryPath = useMemo(() => new URLSearchParams(search).get('path'), [search]);

    useEffect(() => {
        if (queryPath) navigate(queryPath);
    }, [queryPath]);

    useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
    }, []);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
    }, [pathname]);

    return (
        <>
            <Navigator pages={['work', 'contact']} />
            <Main>
                <Outlet />
            </Main>
            <Footer>DI AY 21 © | All rights reserved.</Footer>
            <LoadScreen isVisible={isLoadingGlobal} />
        </>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Main = styled.main`
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Footer = styled.footer`
    z-index: 1;
    text-align: center;
    padding: 10px 0;
    margin: 0 20px;
    font-size: 12px;
    color: ${Color.grey3};
    position: absolute;
    bottom: 0;
    width: calc(100vw - 40px);
`;
