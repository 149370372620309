import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const LogoSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path d="M2560.12,912.82h1.09l233.88-640.44C2795.09,270.68,2677.91,588.89,2560.12,912.82z" />
            <path d="M2557.62,919.66c-125.14,344.18-248.62,683.72-262.25,721.1l263.34-721.1H2557.62z" />
            <path
                d="M987.32,83.9c-62.18-29.25-131.5-48.45-231.02-48.45H23.09c-1.89,0-3.41,1.44-3.41,3.36
       c0,1.9,1.54,3.46,3.41,3.46H39.6c36.97,1.54,66.52,31.46,66.52,68.82c0,0.55,0.09,1513.4,0.09,1513.68
       c0,46.17-37.43,82.18-83.59,82.18c-1.44,0-3.07,1.35-3.07,3.23c0,2.32,1.53,3.6,3.41,3.6h607.38c7.85,0,17,0.13,27.21,0.26
       c12.88,0.17,27.46,0.35,43.31,0.35c36.42,0,79.5-1.21,123.44-5.73c8.67-0.79,17.03-1.74,25.11-2.78c0.3-0.04,0.61-0.09,0.91-0.13
       c13.53-1.76,26.32-3.88,38.35-6.35c0.5-0.1,1.01-0.22,1.51-0.33c8.77-1.84,17.42-3.79,25.5-6.04
       c24.64-6.21,48.02-14.32,69.48-24.59c281.07-115.93,473.79-433.75,473.79-793.84c0-187.94-50.83-366.78-146.98-513.87
       C1229.01,233.78,1114.98,137.17,987.32,83.9z M1143.46,1387.28c-47.51,133.65-120.27,223-221.6,273.64
       c-1.09,0.53-2.29,0.98-3.4,1.51c-9.84,4.8-20.17,8.93-30.54,13.02c-66.31,23.38-145.75,29.99-212.48,31.52
       c-79.81-0.2-272.54-0.17-273.98-0.27c-43.46-2.97-77.93-37.79-77.93-82c0-2.55,0-1513.03,0-1513.63
       c0-36.56,28.38-66.13,64.27-68.82c0,0,291.6,0.22,314.94,0.36c13.76,0.17,27.2,0.56,39.83,1.47c2.68,0.2,5.3,0.45,7.94,0.68
       c12.02,1.02,23.43,2.45,34.55,4.11c4.35,0.65,8.73,1.25,12.99,2c10.11,1.77,19.79,3.85,29.26,6.11c3.52,0.82,7.15,1.53,10.59,2.43
       c11.18,2.92,21.9,6.24,32.33,9.81c1.58,0.56,3.28,0.98,4.85,1.54c1.44,0.5,2.75,1.14,4.18,1.66
       c123.93,45.83,210.44,140.78,264.19,290.52c42.41,118.09,67.22,281.49,67.22,511.63
       C1210.67,1092.36,1188.7,1260.07,1143.46,1387.28z"
            />
            <path
                d="M2051.25,43.79c4.41-0.86,8.98-1.54,13.64-1.54c2.98,0,14.19,0.01,14.28,0c1.84-0.06,3.33-1.58,3.33-3.44
       c0-1.9-1.51-3.36-3.4-3.36h-383.43c-1.9,0-3.46,1.44-3.46,3.36c0,1.86,1.48,3.39,3.31,3.44c0.09,0.01,19.95-0.23,27.95,1.54
       c31.16,6.9,54.56,34.52,54.56,67.75c0,0.61,0,1509.31,0,1511.86c0,43.03-32.7,78.24-74.51,82.85c-2.55,0.24-5.53,0.22-7.52,0.22
       c-1.99,0-3.79,1.22-3.79,3.47c0,2.46,1.46,3.82,3.34,3.82h383.49c1.89,0,3.46-1.96,3.46-3.82c0.01-1.92-1.53-3.47-3.41-3.47
       c0,0-5.96-0.46-7.89-0.63c-42.46-3.98-75.82-38.88-75.82-82.39c0-0.26,0.07-1511.37,0.07-1511.9
       C1995.44,77.86,2019.42,50.07,2051.25,43.79z"
            />
            <path
                d="M3614.36,1706.27c0,0-6.81,0.48-14.19-0.22c-46.85-4.38-65.74-24.07-75.59-50.83
       c-8.02-21.77-591.86-1622.07-591.86-1622.07c-46.5,21.8-82.46,61.11-100.01,109.37l-549.15,1510.32
       c-18.69,50.74-33.16,53.62-68.98,53.62c-1.67,0-14.59-0.1-19.81,0.03c-1.89,0-3.41,1.56-3.41,3.43c0,2.31,1.57,3.82,3.46,3.82
       h151.2c1.04,0,3.7-1.22,3.7-3.82c0-1.9-1.63-3.26-3.52-3.26c-22.27,0-37.98-6.32-46.68-18.79c-10.49-15-8.1-36.25-5.42-43.64
       l1.27-3.49c13.63-37.38,137.11-376.92,262.25-721.1h1.09l2.5-6.84h-1.09c117.79-323.92,234.97-642.14,234.97-640.44l499.66,1370.09
       c5.91,14.56,5.14,33.02-11.11,49.57c-8.62,8.79-20.64,13.97-34.01,14.18l-19.48,0.06c-1.17,0.06-3.07,1.38-3.07,3.52
       c0,1.9,1.53,3.8,3.41,3.8h383.69c1.89,0,3.43-2.09,3.43-3.99C3617.62,1708.1,3616.13,1706.27,3614.36,1706.27z"
            />
            <path
                d="M4795.76,66.29c7.69-11.67,12.92-21.58,27.42-23.35c6.8-0.82,41.43-0.68,41.43-0.68c1.4,0,3.2-1.24,3.2-3.47
       c0.01-2.69-2.05-3.34-3.95-3.34c0,0-148.5-0.03-149.81-0.03c-1.9,0-3.75,1.48-3.73,3.39c0.01,2.29,1.8,3.46,3.2,3.46
       c0,0,11.34,0.19,14.52,0.66c20.44,2.68,35.18,18,38.33,37.97h20.85C4791.06,74.17,4794.61,68.02,4795.76,66.29z"
            />
            <path
                d="M3716.71,78.64c-1.8-3.37-3.23-8.14-3.23-12.22c0-13.27,10.73-24.1,24.1-24.1c0.82,0,5.37-0.01,10.92-0.04
       h24.81c2.29,0,3.41-2.35,3.41-3.41c0-1.9-1.5-3.4-3.41-3.4h-383.45c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.41,3.4,3.41h14.32
       c33.61,0.71,56.89,21.15,76.84,50.44l0.17,0.26l590.04,1016.09c-0.13,258.44-0.6,513.64-0.6,514.53c0,43.03-32.5,78.21-74.31,82.82
       c-1.63,0.16-3.44-0.12-5.04,0.06h-2.98c-1.9,0-3.41,1.57-3.41,3.47c0,1.9,1.51,3.8,3.41,3.8h383.45c1.9,0,3.44-1.9,3.44-3.8
       c0-1.9-1.61-3.47-3.53-3.47h-3.01c-1.71,0-3.56,0.1-4.87-0.03c-42.46-3.98-75.82-39.3-75.82-82.81c0-0.1,0.63-284.99,0.67-577.18
       C4288.62,1042.06,3716.71,78.64,3716.71,78.64z"
            />
            <path
                d="M5226.1,315.22c0-141.8-106.23-262.91-286.88-276.56c-8.78-0.9-17.66-1.36-26.62-1.53v4
       c42.4,2.04,189.21,30.14,189.21,300.18c0,62.92-17.03,129.28-40.64,190.27c-30.16,77.99-72.75,150.38-125.09,215.28h10.79l1.46-1.5
       C5128.16,568.56,5226.1,479.27,5226.1,315.22z"
            />
            <path
                d="M4666.61,1029.25c-0.08,0.08-0.07,0.22-0.13,0.32l-1.2,1.23c-0.35,0.43-0.39,1.07-0.15,1.55
       c0.25,0.51,0.76,0.85,1.34,0.85l336.01,0.03l168.71,0.03c0.47,0,0.91-0.21,1.19-0.59l47.89-99.76h-459.95L4666.61,1029.25z"
            />
            <path
                d="M5435.69,38.76c-0.08-0.08-0.22-0.07-0.32-0.13l-1.23-1.2c-0.43-0.35-1.07-0.39-1.55-0.15
       c-0.51,0.25-0.85,0.76-0.85,1.34l-0.03,336.01l-0.03,168.71c0,0.47,0.21,439.46,0.59,439.74l99.76,47.89V132.46L5435.69,38.76z"
            />
        </svg>
    );
};

LogoSVG.defaultProps = {
    fill: 'currentColor',
    version: '1.1',
    id: 'Layer_1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
    width: '100%',
    height: '100%',
    x: '0px',
    y: '0px',
    viewBox: '0 0 5552 1747',
    xmlSpace: 'preserve',
};
