import styled from 'styled-components/macro';
import { useWindow } from 'hooks';
import { Clients, RecentWork, Section, Slogan, ScrollIndicator, About2Contact } from 'components';
import { Color } from 'SharedStyles.styles';

export function StartPage() {
    const { scrollTop, windowHeight } = useWindow();

    return (
        <Container>
            <Section>{scrollTop <= windowHeight * 2 && <Slogan />}</Section>
            <Section scrollLengthFactor={3.4}>
                <RecentWork />
            </Section>
            <Section isPseudo />
            <Section backgroundColor={Color.grey2}>
                <Clients />
            </Section>
            <Section backgroundColor={Color.black}>
                <About2Contact />
            </Section>
            <ScrollIndicator isVisible={scrollTop <= 0} />
        </Container>
    );
}

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
