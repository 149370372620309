import { useDebounce, useLoadState } from 'hooks';
import { useEffect, useState } from 'react';

type Props = {
    globalId: string;
    loadInBackground?: boolean;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Img: React.FC<Props> = ({ globalId, loadInBackground, src, onLoad, onError, alt, ...props }) => {
    const { unsubscribe } = useLoadState(globalId, {
        remember: true,
        isNonBlocking: loadInBackground,
    });
    const [source, setSource] = useState(src);
    const debounce = useDebounce();

    useEffect(() => {
        if (src?.endsWith('.gif')) {
            setSource('');
            debounce(() => setSource(src), 100);
        } else {
            setSource(src);
        }
    }, [src]);

    return (
        <img
            src={source}
            onLoad={(e) => {
                unsubscribe();
                onLoad && onLoad(e);
            }}
            onError={(e) => {
                unsubscribe();
                onError && onError(e);
            }}
            alt={alt || ''}
            {...props}
        />
    );
};
