import { Color, Constants, blink } from 'SharedStyles.styles';
import styled from 'styled-components/macro';

type Props = {
    isVisible?: boolean;
};

export const ScrollIndicator: React.FC<Props> = ({ isVisible }) => {
    return (
        <Container>
            <Icon isVisible={isVisible}>
                <Text>{'scroll'}</Text>
                <Arrow />
            </Icon>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    position: absolute;
    top: 0;
    height: 100vh;
    height: 100svh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const Icon = styled.div<{ isVisible?: boolean }>`
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    margin-bottom: 5svh;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.5s;

    @media (max-width: ${Constants.mobileWidth}) {
        transform: scale(0.6);
    }
`;

const Text = styled.div`
    color: ${Color.white};
    font-size: 30px;
`;

const Arrow = styled.div`
    height: 50px;
    width: 50px;
    transform: rotate(315deg);
    top: -20px;
    position: relative;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    animation: ${blink} 3s infinite;
    filter: drop-shadow(-10px 11px ${Color.secondary}) drop-shadow(-10px 11px ${Color.primary});
    box-sizing: border-box;
`;
