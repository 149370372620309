import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const ApartmentSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path d="M86 970V346h165V181h459v330h165v459H533V771H427v199H86Zm94-94h105V771H180v105Zm0-165h105V606H180v105Zm0-165h105V441H180v105Zm165 165h105V606H345v105Zm0-165h105V441H345v105Zm0-165h105V276H345v105Zm165 330h105V606H510v105Zm0-165h105V441H510v105Zm0-165h105V276H510v105Zm165 495h105V771H675v105Zm0-165h105V606H675v105Z" />
        </svg>
    );
};

ApartmentSVG.defaultProps = {
    fill: 'currentColor',
    viewBox: '0 96 960 960',
    height: '960',
    width: '960',
    xmlns: 'http://www.w3.org/2000/svg',
};
