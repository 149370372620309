import styled from 'styled-components/macro';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindow } from 'hooks';
import { LogoSVG } from './svg/LogoSVG';
import { Color, Constants } from 'SharedStyles.styles';

type Props = {
    pages: string[];
};

export const Navigator: React.FC<Props> = ({ pages }) => {
    const { pathname } = useLocation();
    const ref = useRef<HTMLDivElement>(null);
    const [top, setTop] = useState<number>();
    const { scrollTop, scrollDir } = useWindow();
    const navigate = useNavigate();

    const splittedPath = pathname.split('/');
    const currentPageIndex = ['', ...pages].indexOf(splittedPath[1]);

    useEffect(() => {
        if (ref.current) {
            if (scrollTop > ref.current.offsetHeight) {
                if (scrollDir === 'up') setTop(0);
                if (scrollDir === 'down') setTop(-100);
            }
            if (scrollTop === 0) setTop(undefined);
        }
    }, [scrollTop, scrollDir]);

    return (
        <>
            <Container ref={ref} style={{ top, position: top === 0 ? 'fixed' : 'sticky' }}>
                <Isle hasBackground={top !== undefined}>
                    <Items>
                        <Link onClick={() => navigate('')}>
                            <Logo>
                                <LogoSVG />
                            </Logo>
                        </Link>
                        {pages.map((slug, i) => (
                            <Link key={i} onClick={() => navigate(slug)}>
                                <Title>{slug.toUpperCase()}</Title>
                            </Link>
                        ))}
                    </Items>
                    <Selector style={{ transform: `translateX(${130 * (currentPageIndex - 1)}px)` }} />
                </Isle>
            </Container>
            {top === 0 && <div style={{ height: ref.current?.offsetHeight }} />}
        </>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.nav`
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 14px 8px 8px;
    transition: top 0.3s;
    z-index: 999;

    @media (max-width: ${Constants.mobileWidth}) {
        padding: 0;
    }
`;

const Isle = styled.div<{ hasBackground?: boolean }>`
    padding: 10px 18px;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({ hasBackground }) => (hasBackground ? Color.shadow : '')};
    transition: background-color 0.5s;
    border-radius: 10px;

    @media (max-width: ${Constants.mobileWidth}) {
        transform: scale(0.7);
    }
`;

const Items = styled.div`
    display: flex;
    justify-content: center;
`;

const Selector = styled.div`
    height: 1px;
    width: 130px;
    background-color: ${Color.white};
    transition: transform 0.2s;
`;

const Logo = styled.div`
    height: 20px;
    width: 130px;
    transition: height 0.2s;
`;

const Title = styled.div`
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-weight: 600;
    color: ${Color.white};
    transition: font-size 0.2s;
`;

const Link = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 43px;
    color: ${Color.white};
    user-select: none;
    cursor: pointer;

    &:hover {
        ${Title} {
            font-size: 16px;
        }

        ${Logo} {
            height: 16px;
        }
    }
`;
