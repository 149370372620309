import styled from 'styled-components/macro';
import { useWindow } from 'hooks';
import { rotate } from 'SharedStyles.styles';
import { SloganSVG } from './svg';

type Props = {};

export const Slogan: React.FC<Props> = () => {
    const { scrollTop, windowHeight } = useWindow();

    const deg = ((scrollTop * 1.6) / windowHeight) * 180;

    return (
        <Container>
            <Rotator>
                <div style={{ transform: `rotate(${deg}deg)` }}>
                    <InnerCircle>
                        <SloganSVG />
                    </InnerCircle>
                </div>
            </Rotator>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    margin-top: 15vh;
    margin-top: 15svh;
`;

const Rotator = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    width: 100%;
    text-align: center;
`;

const InnerCircle = styled.div`
    display: flex;
    animation: ${rotate} infinite 70s linear;
    height: 50vh;
    height: 50svh;
    width: 80vw;
`;
