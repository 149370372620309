import styled from 'styled-components/macro';
import trailblazer from '../assets/trailblazer.png';

type Props = {
    client: ClientKey;
    useFilter?: boolean;
};

type ClientKey = 'trailblazer';

const SOURCES = {
    trailblazer: trailblazer,
};

export const ClientLogo: React.FC<Props> = ({ client, useFilter }) => {
    const src = SOURCES[client];
    return (
        <Container>
            <img style={useFilter ? { filter: 'invert(100%)' } : undefined} src={src} alt="Client or partner Logo" />
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
`;
