import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const MailSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path d="M149 921q-39.05 0-66.525-27.475Q55 866.05 55 827V325q0-39.463 27.475-67.231Q109.95 230 149 230h662q39.463 0 67.231 27.769Q906 285.537 906 325v502q0 39.05-27.769 66.525Q850.463 921 811 921H149Zm331-295 331-223v-78L480 543 149 325v78l331 223Z" />
        </svg>
    );
};

MailSVG.defaultProps = {
    fill: 'currentColor',
    height: '960',
    width: '960',
    viewBox: '0 96 960 960',
    xmlns: 'http://www.w3.org/2000/svg',
};
