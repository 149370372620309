import styled from 'styled-components/macro';
import { Planet, Showcase, Stars } from '../components';
import { useCMS, useWindow } from 'hooks';
import { useEffect, useRef } from 'react';

export const RecentWork: React.FC = () => {
    const { top3 } = useCMS();
    const { scrollTop } = useWindow();
    const ref = useRef<HTMLDivElement>(null);
    const top = ref.current ? ref.current.getBoundingClientRect().top : 1;
    const isThere = top <= 20;
    const start = useRef<number>();

    useEffect(() => {
        if (isThere && !start.current) start.current = scrollTop;
    }, [isThere]);

    return (
        <Container>
            {top3.length && (
                <>
                    {top3.map((c, i) => (
                        <Showcase caseData={c} key={i} slidesToLeft={i % 2 === 0} />
                    ))}
                </>
            )}
            <Space>
                <Background ref={ref}>
                    <StarContainer style={isThere && start.current ? { transform: `translateY(${(start.current - scrollTop) / 8}px)` } : {}}>
                        <Stars />
                    </StarContainer>
                </Background>
                <Planet />
            </Space>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    width: 100vw;
`;

const Space = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
`;

const Background = styled.div`
    top: 0;
    position: absolute;
    width: 100vw;
    height: 112vh;
`;

const StarContainer = styled.div`
    height: 100%;
`;
