import styled from 'styled-components/macro';
import { Img } from './Img';
import { useWindow } from 'hooks';
import { Color } from 'SharedStyles.styles';

type Props = {
    imageData: {
        image: string;
        caption: string;
        layoutType: Layout;
    };
};

/**
 * 1: Image screen-width, text below
 * 2: Image screen-width, text on image
 * 3: Image left, text right
 * 4: Image right, text left
 */
type Layout = '1' | '2' | '3' | '4';

export const CaseSection: React.FC<Props> = ({ imageData }) => {
    const { layoutType } = imageData;
    const { isMobile } = useWindow();

    const getLayout = (id: Layout) => {
        if (isMobile && (id === '3' || id === '4')) return <Layout1 imageData={imageData} />;
        switch (id) {
            case '1':
                return <Layout1 imageData={imageData} />;
            case '2':
                return <Layout2 imageData={imageData} />;
            case '3':
                return <Layout3 imageData={imageData} />;
            case '4':
                return <Layout4 imageData={imageData} />;
            default:
                return <Layout1 imageData={imageData} />;
        }
    };

    return <Container>{getLayout(layoutType)}</Container>;
};

/* -------------------------------------------------------------------------- */
/*                                  LAYOUTS                                   */
/* -------------------------------------------------------------------------- */

const Layout1: React.FC<Props> = ({ imageData }) => {
    const { image, caption } = imageData;
    return (
        <Layout1Container>
            <ImageContainer>
                <Img globalId={`${image}`} src={image} />
            </ImageContainer>
            {caption && <Caption>{caption}</Caption>}
        </Layout1Container>
    );
};

const Layout2: React.FC<Props> = ({ imageData }) => {
    const { image, caption } = imageData;
    return (
        <Layout2Container>
            <ImageContainer>
                <Img globalId={`${image}`} src={image} />
                {caption && <Caption>{caption}</Caption>}
            </ImageContainer>
        </Layout2Container>
    );
};

const Layout3: React.FC<Props> = ({ imageData }) => {
    const { image, caption } = imageData;
    return (
        <Layout3Container>
            <ImageContainer>
                <Img globalId={`${image}`} src={image} />
            </ImageContainer>
            <Caption>{caption}</Caption>
        </Layout3Container>
    );
};

const Layout4: React.FC<Props> = ({ imageData }) => {
    const { image, caption } = imageData;
    return (
        <Layout4Container>
            <ImageContainer>
                <Img globalId={`${image}`} src={image} />
            </ImageContainer>
            <Caption>{caption}</Caption>
        </Layout4Container>
    );
};
/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.section`
    display: flex;
    width: 100vw;
    margin-bottom: 10vh;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: auto;
        max-height: 90vh;
        max-height: 90svh;
    }
`;

const Caption = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    font-size: 3vh;
    white-space: pre-wrap;
`;

const Layout1Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-self: flex-start;

    ${ImageContainer} {
        img {
            width: 100vw;
            height: auto;
            max-height: initial;
        }
    }
`;

const Layout2Container = styled.div`
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;

    ${ImageContainer} {
        position: relative;
    }

    ${Caption} {
        background: linear-gradient(0deg, ${Color.black}, transparent);
        align-items: flex-start;
        max-height: 50%;
        overflow-y: auto;
        position: absolute;
        bottom: 0;
        filter: drop-shadow(${Color.black} -1px 2px 2px);
    }
`;

const Layout3Container = styled.div`
    display: flex;
    width: 100vw;

    ${ImageContainer}, ${Caption} {
        flex: 1 1 50%;
        max-width: 50%;
        img {
            max-width: 100%;
        }
    }

    ${ImageContainer} {
        padding: 50px;
        box-sizing: border-box;
        justify-content: flex-end;
    }

    ${Caption} {
        padding-left: 50px;
        justify-content: flex-start;
        text-align: left;
    }
`;

const Layout4Container = styled(Layout3Container)`
    flex-direction: row-reverse;

    ${Caption} {
        padding: 20px 50px 20px 20px;
        justify-content: flex-end;
        text-align: right;
    }

    ${ImageContainer} {
        padding: 50px;
        box-sizing: border-box;
        justify-content: flex-start;
    }
`;
