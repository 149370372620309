import { useEffect, useState } from 'react';

type Size = {
    width: number;
    height: number;
};

type UseResizeObserver = (ref: React.RefObject<Element>) => {
    width: number;
    height: number;
};

export const useResizeObserver: UseResizeObserver = (ref) => {
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });

    useEffect(() => {
        const target = ref.current;

        if (!target) {
            return;
        }

        const resizeObserver = new ResizeObserver(([entry]) => {
            const { width, height } = entry.contentRect;
            setSize({ width, height });
        });

        resizeObserver.observe(target);

        return () => {
            resizeObserver.unobserve(target);
        };
    }, [ref]);

    return size;
};

export default useResizeObserver;
