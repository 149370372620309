import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const UniversalMusicGroupSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M630.688,175.361c-17.353,53.238-66.11,94.66-127.918,94.66
		c-65.538,0-109.603-40.864-127.292-93.504c18.404,49.039,68.488,84.199,127.39,84.199
		C562.222,260.716,612.632,225.001,630.688,175.361 M375.183,94.12C392.613,41.018,433.852,0,502.77,0
		c64.059,0,111.133,41.579,128.201,95.282C613.163,45.233,562.526,9.119,502.867,9.119C443.655,9.119,393.352,44.672,375.183,94.12"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M505.482,81.005c0.828-1.211,3.309-0.404,5.069-0.362
		c2.382,0.047,1.814,3.498,5.078,2.81c3.262-0.682,3.306,3.06,7.962,2.928c4.684-0.145-0.93,1.607-0.93,1.607
		s-2.889-1.374-4.768-0.458c-0.98,0.483,0.371-0.272-1.274-1.612c-2.568-2.066-2.229,0.392-6.538-3.217
		C507.054,80.16,504.138,83.004,505.482,81.005 M528.91,175.245c1.858,0.74,4.17,2.57,5.241,5.351
		c2.524,6.595-1.227,1.249-1.227,11.193c0,9.156-2.622,7.671-2.622,7.671s-0.568,10.82-6.231,17.465
		c-3.615,3.577-0.808,6.539-0.808,6.539s-1.227,0.667-2.997,8.516c-0.567,2.544,2.727,6.461,5.599,5.484
		c2.136-0.73-5.221-1.43,2.755-4.752c3.078-1.283,0.561-3.693,0.561-3.693s5.167-0.382,8.083-8.079
		c5.137,0.736,3.227-2.365,13.813-4.614c2.799-0.598,3.39-1.425,3.045-4.024c-0.352-2.587,3.45,0.38,13.936-10.156
		c8.269-8.319,5.289-10.191,10.999-13.214c6.039-3.168,6.593,0.283,8.614-2.312c4.629-5.903,2.99-5.539,4.913-9.298l1.365-2.088
		L528.91,175.245z M470.761,87.743c-1.717-4.178-1.602-10.947,2.069-17.207c2.312-3.91,8.441-5.642,13.793-3.583
		c6.008-4.668,7.438,0.354,12.756-1.916c6.984-2.985,3.071,7.905,9.84,10.663c2.795-7.344-6.376-12.859-0.625-16.326
		c4.105-2.463,7.198-3.993,8.877-7.304c2.399-4.707,3.339-8.146,5.177-8.771c4.265-1.428,3.346-5.42,3.346-5.42
		s4.528,1.313,8.06-1.95c-2.693-1.426-6.623-2.663-5.59-5.508c1.21-3.362,10.344,3.472,13.037-2.042
		c-5.42-2.335-10.965-7.397-14.28-8.563c-2.281,2.44-3.696,1.458-6.63-0.834c-4.961-3.866-5.38-0.181-8.481-2.851
		c-2.48,4.908,1.247,4.518,2.402,5.747c3.187,3.438-3.23-3.09-2.612,5.684c0.162,2.281-3.251,2.696-5.792-2.246
		c-1.324-2.571-11.867-2.024-13.459-4.486c1.453-2.862,13.861-13.059,32.92-10.614c-27.682-10.712-65.085,0.999-65.458,5.069
		c-3.727,2.305-2.445,4.93-4.303,5.545c-3.217,1.061-6.738,10.765-6.738,10.765s2.006,1.319-4.251,8.223
		c-5.155,5.684-6.684,14.303-6.684,14.303s3.409,6.14,2.764,13.219c-0.487,5.395-0.717,1.396,1.667,5.309
		c1.385,2.289,2.901,10.283,3.823,7.166c1.764-5.956-3.207-11.457-2.386-17.376c0.183,0.737,1.911-2.342,1.844,0.446
		c-0.191,7.308,9.134,21.39,9.134,21.39s-3.48,3.475,0.15,3.475L470.761,87.743z"
                />
                <path
                    d="M272.396,107.324h-21.022l3.137,3.685v27.195c0,6.122-4.107,8.7-10.241,8.7c-6.131,0-10.247-2.578-10.247-8.7v-27.195
		l3.143-3.685h-21.018l3.137,3.685v27.638c0,13.572,7.711,18.803,24.985,18.803c17.277,0,24.986-5.231,24.986-18.803v-27.638
		L272.396,107.324z"
                />
                <polygon
                    points="345.352,107.324 327.615,107.324 330.771,111.009 330.771,135.776 305.338,107.324 289.396,107.324 
		292.546,111.009 292.546,152.804 289.396,156.489 307.12,156.489 303.984,152.804 303.984,127.605 330.992,156.489 
		342.209,156.489 342.209,111.009 	"
                />
                <polygon
                    points="386.198,156.489 383.055,152.804 383.055,111.009 386.198,107.324 365.029,107.324 368.171,111.009 
		368.171,152.804 365.029,156.489 	"
                />
                <polygon
                    points="456.002,107.324 438.196,107.324 441.339,111.009 428.778,138.065 416.656,111.009 419.803,107.324 
		398.851,107.324 422.115,156.489 432.512,156.489 	"
                />
                <polygon
                    points="515.441,156.489 515.441,143.151 511.7,146.248 486.194,146.248 486.194,136.802 502.347,136.802 506.02,139.907 
		506.02,123.905 502.347,127.006 486.194,127.006 486.194,117.566 511.7,117.566 515.441,120.665 515.441,107.324 468.472,107.324 
		471.614,111.009 471.614,152.804 468.472,156.489 	"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M586.944,156.489l-13.523-19.388c6.646-2.659,11.276-7.741,11.276-14.887
		c0-8.189-5.079-14.89-19.295-14.89h-31.109l3.149,3.685v41.795l-3.149,3.685h20.87l-3.136-3.685v-13.932h6.271l9.563,13.932
		l-3.13,3.685H586.944z M569.754,123.243c0,3.54-3.446,6.417-8.238,6.417h-9.488v-12.094h11.286
		C566.598,117.566,569.754,119.198,569.754,123.243"
                />
                <path
                    d="M650.138,142.119c0-7.816-6.363-12.164-15.564-15.113c-5.981-1.837-14.429-3.162-14.429-7.075
		c0-1.919,2.311-3.25,6.353-3.25c5.396,0,14.209,2.215,21.086,5.457V105.71l-3.957,3.753c-4.19-1.698-11.448-3.464-16.453-3.464
		c-16.967,0-22.441,7.37-22.441,14.083c0,9.136,6.134,13.12,15.788,16.437c7.167,2.428,13.827,2.651,13.827,6.696
		c0,3.476-4.105,3.918-6.2,3.918c-5.31,0-14.737-1.849-23.117-7.225v18.202l3.967-4.276c3.896,1.481,12.645,3.98,19.822,3.98
		C643.545,157.813,650.138,151.401,650.138,142.119"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M724.744,156.489l-24.532-49.165h-16.83l1.787,3.685l-22.214,45.48h18.331
		l-3.143-3.685l2.464-5.156h23.784l2.389,5.156l-3.143,3.685H724.744z M700.732,138.802h-16.54l8.237-17.617L700.732,138.802z"
                />
                <polygon
                    points="783.853,156.489 783.853,143.151 780.104,146.248 756.1,146.248 756.1,111.009 759.235,107.324 738.365,107.324 
		741.508,111.009 741.508,152.804 738.365,156.489 	"
                />
                <path
                    d="M770.531,114.14c0,3.443,2.822,6.217,6.296,6.217c3.501,0,6.322-2.774,6.322-6.217c0-3.438-2.821-6.221-6.322-6.221
		C773.354,107.918,770.531,110.701,770.531,114.14 M769.869,114.14c0-3.79,3.119-6.865,6.958-6.865c3.859,0,6.975,3.075,6.975,6.865
		c0,3.793-3.115,6.873-6.975,6.873C772.988,121.013,769.869,117.933,769.869,114.14"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M779.459,111.457c-0.695-0.575-1.51-0.838-2.409-0.838
		c-2.048,0-3.575,1.565-3.575,3.54c0,1.994,1.497,3.582,3.549,3.582c0.939,0,1.831-0.335,2.527-0.945v-0.909
		c-0.636,0.748-1.564,1.228-2.552,1.228c-1.639,0-2.845-1.407-2.845-2.957c0-1.553,1.22-2.925,2.858-2.925
		c0.987,0,1.788,0.392,2.446,1.106V111.457z"
                />
                <g>
                    <path
                        d="M38.827,336.174l-2.168,2.483v18.693c0,2.321-0.345,4.285-1.034,5.894c-0.689,1.604-1.723,2.909-3.1,3.906
			c-1.379,1-3.145,1.737-5.295,2.217c-2.152,0.477-4.756,0.716-7.816,0.716c-3.043,0-5.635-0.239-7.779-0.716
			c-2.142-0.479-3.911-1.217-5.307-2.217c-1.396-0.997-2.437-2.305-3.126-3.92s-1.034-3.571-1.034-5.88v-18.693L0,336.174h14.522
			l-2.168,2.483v18.403c0,1.979,0.625,3.456,1.877,4.43s2.98,1.46,5.182,1.46c2.202,0,3.934-0.48,5.194-1.446
			c1.26-0.967,1.891-2.446,1.891-4.443v-18.403l-2.167-2.483H38.827z"
                    />
                    <path
                        d="M94.498,336.174l-2.169,2.483v30.771h-7.765L65.931,349.88v17.064l2.169,2.483H55.871l2.169-2.483v-28.287
			l-2.169-2.483h10.994l17.548,19.254v-16.771l-2.169-2.483H94.498z"
                    />
                    <path d="M127.93,369.428h-14.598l2.168-2.483v-28.287l-2.168-2.483h14.598l-2.167,2.483v28.287L127.93,369.428z" />
                    <path
                        d="M181.584,336.174l-16.211,33.254h-7.186l-16.061-33.254h14.473l-2.169,2.483l8.372,18.305l8.673-18.305
			l-2.168-2.483H181.584z"
                    />
                    <path
                        d="M227.951,369.428h-32.425l2.169-2.483v-28.287l-2.169-2.483h32.425v9.032l-2.597-2.095h-17.599v6.38H218.9
			l2.546-2.096v10.811l-2.546-2.096h-11.145v6.38h17.599l2.597-2.095V369.428z"
                    />
                    <path
                        d="M282.612,369.428h-15.33l2.169-2.483l-6.606-9.421h-4.337v9.421l2.169,2.483h-14.397l2.167-2.483v-28.287
			l-2.167-2.483h21.481c4.64,0,8.017,0.926,10.136,2.774c2.117,1.852,3.176,4.284,3.176,7.302c0,2.321-0.698,4.343-2.093,6.063
			c-1.394,1.72-3.295,3.052-5.697,3.994L282.612,369.428z M270.738,346.933c0-0.875-0.206-1.598-0.618-2.166
			c-0.413-0.567-0.955-0.986-1.625-1.254c-0.672-0.267-1.404-0.401-2.195-0.401h-7.791v8.181h6.556c1.63,0,2.983-0.423,4.06-1.268
			C270.199,349.18,270.738,348.149,270.738,346.933z"
                    />
                    <path
                        d="M331.525,359.713c0,1.608-0.289,3.052-0.87,4.335c-0.58,1.281-1.479,2.393-2.696,3.336
			c-1.22,0.939-2.749,1.666-4.589,2.176c-1.842,0.514-4.021,0.768-6.544,0.768c-1.529,0-3.173-0.146-4.929-0.437
			c-1.757-0.294-3.453-0.665-5.092-1.122c-1.639-0.452-2.862-0.834-3.669-1.142l-2.748,2.896v-12.316
			c2.622,1.655,5.408,2.882,8.358,3.687c2.95,0.804,5.483,1.206,7.602,1.206c1.091,0,2.08-0.183,2.961-0.547
			c0.884-0.365,1.325-1.068,1.325-2.109c0-0.827-0.387-1.459-1.161-1.898c-0.772-0.437-2.198-0.926-4.273-1.471
			c-2.077-0.544-3.45-0.929-4.123-1.158c-3.665-1.23-6.396-2.674-8.194-4.319c-1.799-1.648-2.698-3.916-2.698-6.806
			c0-1.639,0.5-3.176,1.5-4.612c1-1.436,2.673-2.612,5.018-3.531c2.345-0.915,5.34-1.375,8.988-1.375
			c0.84,0,1.739,0.061,2.698,0.183c0.958,0.121,2.016,0.304,3.176,0.547c1.159,0.243,2.185,0.5,3.075,0.768
			c0.892,0.271,1.689,0.547,2.396,0.841l2.749-2.53v11.124c-1.631-0.747-3.342-1.399-5.131-1.96
			c-1.791-0.562-3.509-0.99-5.157-1.291s-3.075-0.449-4.287-0.449c-1.362,0-2.433,0.202-3.213,0.608
			c-0.782,0.405-1.172,0.933-1.172,1.581c0,0.618,0.294,1.152,0.882,1.608c0.588,0.453,1.34,0.849,2.257,1.18
			c0.916,0.334,2.108,0.699,3.58,1.095c1.47,0.398,2.55,0.703,3.239,0.916c3.445,1.084,6.098,2.436,7.955,4.051
			S331.525,357.216,331.525,359.713z"
                    />
                    <path
                        d="M388.381,369.428h-14.573l2.168-2.483l-1.639-3.504h-16.44l-1.689,3.504l2.169,2.483h-12.657l15.33-30.771
			l-1.237-2.483h11.625L388.381,369.428z M371.792,357.449l-5.724-11.905l-5.672,11.905H371.792z"
                    />
                    <path
                        d="M434.495,369.428h-31.389l2.168-2.483v-28.287l-2.168-2.483h14.397l-2.169,2.483v23.833h16.565
			l2.597-2.095V369.428z"
                    />
                    <path
                        d="M520.826,369.428h-14.095l2.168-2.483v-15.97l-9.127,18.453h-4.815l-10.312-18.744v16.261l2.168,2.483
			h-11.925l2.168-2.483v-28.287l-2.168-2.483h12.128l11.193,20.353l10.237-20.353h12.38l-2.168,2.483v28.287L520.826,369.428z"
                    />
                    <path
                        d="M576.696,336.174l-2.167,2.483v18.693c0,2.321-0.345,4.285-1.034,5.894
			c-0.689,1.604-1.724,2.909-3.101,3.906c-1.379,1-3.145,1.737-5.295,2.217c-2.152,0.477-4.756,0.716-7.816,0.716
			c-3.043,0-5.635-0.239-7.778-0.716c-2.143-0.479-3.912-1.217-5.308-2.217c-1.396-0.997-2.436-2.305-3.125-3.92
			s-1.034-3.571-1.034-5.88v-18.693l-2.168-2.483h14.522l-2.168,2.483v18.403c0,1.979,0.625,3.456,1.877,4.43s2.98,1.46,5.182,1.46
			c2.202,0,3.934-0.48,5.194-1.446c1.26-0.967,1.891-2.446,1.891-4.443v-18.403l-2.168-2.483H576.696z"
                    />
                    <path
                        d="M624.553,359.713c0,1.608-0.291,3.052-0.871,4.335c-0.579,1.281-1.479,2.393-2.697,3.336
			c-1.219,0.939-2.748,1.666-4.59,2.176c-1.84,0.514-4.021,0.768-6.542,0.768c-1.53,0-3.173-0.146-4.93-0.437
			c-1.756-0.294-3.454-0.665-5.093-1.122c-1.639-0.452-2.862-0.834-3.668-1.142l-2.749,2.896v-12.316
			c2.622,1.655,5.408,2.882,8.358,3.687c2.95,0.804,5.484,1.206,7.602,1.206c1.093,0,2.08-0.183,2.964-0.547
			c0.882-0.365,1.322-1.068,1.322-2.109c0-0.827-0.387-1.459-1.159-1.898c-0.773-0.437-2.197-0.926-4.274-1.471
			c-2.074-0.544-3.45-0.929-4.122-1.158c-3.663-1.23-6.396-2.674-8.193-4.319c-1.799-1.648-2.698-3.916-2.698-6.806
			c0-1.639,0.501-3.176,1.501-4.612c1-1.436,2.673-2.612,5.016-3.531c2.346-0.915,5.341-1.375,8.989-1.375
			c0.841,0,1.74,0.061,2.698,0.183c0.958,0.121,2.018,0.304,3.176,0.547c1.161,0.243,2.185,0.5,3.077,0.768
			c0.891,0.271,1.689,0.547,2.396,0.841l2.748-2.53v11.124c-1.631-0.747-3.341-1.399-5.132-1.96
			c-1.789-0.562-3.508-0.99-5.155-1.291c-1.646-0.301-3.076-0.449-4.286-0.449c-1.361,0-2.433,0.202-3.215,0.608
			c-0.781,0.405-1.173,0.933-1.173,1.581c0,0.618,0.294,1.152,0.884,1.608c0.588,0.453,1.34,0.849,2.256,1.18
			c0.915,0.334,2.108,0.699,3.58,1.095c1.472,0.398,2.551,0.703,3.24,0.916c3.445,1.084,6.096,2.436,7.954,4.051
			C623.623,355.157,624.553,357.216,624.553,359.713z"
                    />
                    <path d="M657.48,369.428h-14.6l2.169-2.483v-28.287l-2.169-2.483h14.6l-2.17,2.483v28.287L657.48,369.428z" />
                    <path
                        d="M707.981,367.042c-0.639,0.355-1.452,0.713-2.444,1.071c-0.992,0.354-2.085,0.676-3.277,0.96
			c-1.195,0.283-2.484,0.523-3.871,0.72c-1.388,0.192-2.702,0.29-3.945,0.29c-2.689,0-5.225-0.331-7.602-0.996
			c-2.379-0.666-4.498-1.703-6.354-3.116c-1.856-1.412-3.314-3.23-4.374-5.454c-1.06-2.223-1.588-4.805-1.588-7.741
			c0-2.433,0.443-4.707,1.336-6.815c0.891-2.109,2.21-3.947,3.959-5.515c1.747-1.565,3.886-2.778,6.415-3.64
			c2.531-0.858,5.408-1.291,8.636-1.291c1.277,0,2.58,0.088,3.907,0.257c1.328,0.169,2.552,0.386,3.67,0.646
			c1.117,0.26,2.156,0.561,3.114,0.898l2.369-2.189v11.077c-1.277-0.764-2.566-1.399-3.869-1.913
			c-1.303-0.51-2.679-0.902-4.123-1.179c-1.446-0.277-2.95-0.413-4.513-0.413c-3.413,0-5.951,0.943-7.615,2.836
			c-1.664,1.889-2.495,4.305-2.495,7.241c0,1.997,0.428,3.761,1.285,5.295s2.084,2.724,3.681,3.565
			c1.598,0.845,3.421,1.267,5.472,1.267c1.899,0,3.635-0.195,5.206-0.584s2.874-0.838,3.908-1.352
			c1.034-0.511,2.071-1.082,3.113-1.717V367.042z"
                    />
                    <path
                        d="M775.226,355.479l-2.219-2.558h12.278v13.317c-0.724,0.438-1.673,0.889-2.849,1.352
			c-1.178,0.463-2.497,0.885-3.959,1.264c-1.463,0.382-2.941,0.683-4.438,0.902c-1.495,0.22-2.958,0.327-4.386,0.327
			c-3.614,0-6.977-0.607-10.085-1.824c-3.11-1.217-5.598-3.126-7.463-5.721c-1.867-2.596-2.8-5.85-2.8-9.763
			c0-3.909,0.983-7.164,2.95-9.763c1.967-2.595,4.509-4.494,7.627-5.697c3.118-1.199,6.374-1.801,9.771-1.801
			c2.419,0,4.42,0.183,6.001,0.548c1.58,0.368,3.36,0.882,5.345,1.547l2.749-2.483v11.418c-2.204-1.203-4.396-2.143-6.581-2.824
			c-2.185-0.683-4.521-1.021-7.011-1.021c-1.579,0-2.992,0.237-4.235,0.717s-2.298,1.166-3.163,2.058
			c-0.866,0.893-1.525,1.95-1.98,3.177c-0.452,1.227-0.681,2.602-0.681,4.126c0,3.068,0.896,5.521,2.685,7.363
			c1.791,1.845,4.25,2.764,7.375,2.764c1.277,0,2.967-0.186,5.069-0.561V355.479z"
                    />
                    <path
                        d="M838.409,369.428h-15.33l2.17-2.483l-6.606-9.421h-4.337v9.421l2.169,2.483h-14.396l2.167-2.483v-28.287
			l-2.167-2.483h21.481c4.639,0,8.017,0.926,10.136,2.774c2.116,1.852,3.176,4.284,3.176,7.302c0,2.321-0.697,4.343-2.093,6.063
			c-1.395,1.72-3.295,3.052-5.697,3.994L838.409,369.428z M826.534,346.933c0-0.875-0.206-1.598-0.618-2.166
			c-0.412-0.567-0.954-0.986-1.625-1.254c-0.673-0.267-1.404-0.401-2.195-0.401h-7.79v8.181h6.556c1.63,0,2.983-0.423,4.06-1.268
			C825.995,349.18,826.534,348.149,826.534,346.933z"
                    />
                    <path
                        d="M894.256,352.775c0,3.994-0.942,7.303-2.824,9.932c-1.883,2.629-4.324,4.548-7.325,5.758
			c-2.999,1.21-6.23,1.814-9.692,1.814c-3.447,0-6.68-0.608-9.695-1.824c-3.018-1.221-5.476-3.146-7.375-5.782
			c-1.898-2.639-2.849-5.938-2.849-9.897c0-3.034,0.56-5.67,1.676-7.91c1.119-2.241,2.631-4.063,4.539-5.468
			c1.907-1.402,4.029-2.434,6.366-3.092c2.336-0.656,4.781-0.983,7.338-0.983c2.588,0,5.05,0.331,7.387,0.997
			c2.336,0.665,4.441,1.692,6.315,3.078c1.874,1.389,3.365,3.207,4.476,5.454C893.7,347.099,894.256,349.741,894.256,352.775z
			 M883.465,352.775c0-3.23-0.729-5.748-2.182-7.56c-1.454-1.808-3.744-2.713-6.869-2.713c-2.068,0-3.783,0.419-5.146,1.254
			c-1.359,0.834-2.356,2.021-2.987,3.555c-0.63,1.534-0.945,3.355-0.945,5.464c0,1.527,0.172,2.93,0.517,4.21
			c0.345,1.284,0.893,2.379,1.639,3.288c0.749,0.909,1.698,1.605,2.851,2.096c1.15,0.486,2.508,0.729,4.072,0.729
			c1.579,0,2.944-0.243,4.097-0.729c1.15-0.49,2.092-1.187,2.823-2.096s1.27-1.983,1.613-3.227
			C883.292,355.807,883.465,354.384,883.465,352.775z"
                    />
                    <path
                        d="M948.16,336.174l-2.168,2.483v18.693c0,2.321-0.344,4.285-1.033,5.894
			c-0.689,1.604-1.724,2.909-3.101,3.906c-1.379,1-3.145,1.737-5.295,2.217c-2.153,0.477-4.758,0.716-7.816,0.716
			c-3.043,0-5.635-0.239-7.778-0.716c-2.143-0.479-3.912-1.217-5.308-2.217c-1.396-0.997-2.438-2.305-3.127-3.92
			s-1.032-3.571-1.032-5.88v-18.693l-2.17-2.483h14.523l-2.169,2.483v18.403c0,1.979,0.627,3.456,1.879,4.43s2.979,1.46,5.182,1.46
			c2.201,0,3.934-0.48,5.194-1.446c1.26-0.967,1.891-2.446,1.891-4.443v-18.403l-2.168-2.483H948.16z"
                    />
                    <path
                        d="M1000,347.2c0,4.771-1.534,8.059-4.603,9.86c-3.066,1.801-7.056,2.703-11.962,2.703h-6.002v7.181
			l2.168,2.483h-14.396l2.169-2.483v-28.287l-2.169-2.483h18.533c2.704,0,5.27,0.308,7.688,0.926
			c2.422,0.615,4.454,1.743,6.102,3.383C999.176,342.121,1000,344.361,1000,347.2z M989.511,347.784c0-1.736-0.542-2.95-1.627-3.639
			c-1.083-0.689-2.643-1.034-4.677-1.034h-5.773v10.053h4.866c2.471,0,4.29-0.389,5.459-1.166
			C988.927,351.218,989.511,349.815,989.511,347.784z"
                    />
                </g>
            </g>
        </svg>
    );
};

UniversalMusicGroupSVG.defaultProps = {
    fill: 'currentColor',
    version: '1.1',
    id: 'Layer_1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
    x: '0px',
    y: '0px',
    width: '1000px',
    height: '370.522px',
    viewBox: '0 0 1000 370.522',
    enableBackground: 'new 0 0 1000 370.522',
    xmlSpace: 'preserve',
};
