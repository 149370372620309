import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const TresBienSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path
                d="M115.815 24.211C115.378 21.613 114.878 18.951 114.254 16.479C112.506 9.063 106.387 3.486 99.02 2.535C98.895 2.535 98.77 2.535 98.708 2.472C74.234 -0.569995 49.51 -0.760995 24.974 1.775C22.664 2.028 20.478 2.282 18.543 2.535C11.176 3.423 4.995 9 3.247 16.352C2.622 18.887 2.123 21.549 1.686 24.212C0.562 30.992 0 38.091 0 45.19C0 52.162 0.562 59.07 1.623 65.725C2.06 68.387 2.56 70.985 3.122 73.521C4.87 80.937 10.988 86.577 18.418 87.465C20.416 87.718 22.913 87.972 25.785 88.289C48.261 90.507 70.925 90.507 93.463 88.099C95.523 87.909 97.397 87.655 99.145 87.465C106.575 86.515 112.693 80.937 114.441 73.521C115.003 71.049 115.503 68.451 115.94 65.852C117.001 59.197 117.563 52.289 117.563 45.254C117.501 38.028 116.939 30.993 115.815 24.211V24.211Z"
                fill="none"
                data-v-77a80b45=""
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.135 25.479C107.761 23.007 107.261 20.599 106.699 18.254C105.701 14.07 102.204 10.901 98.021 10.394L97.709 10.331C84.848 8.683 71.861 7.923 58.813 7.923C47.824 7.923 36.836 8.493 25.848 9.633C23.475 9.887 21.352 10.141 19.542 10.331C15.359 10.838 11.862 14.007 10.863 18.191C10.302 20.599 9.803 23.007 9.428 25.479C8.366 31.817 7.867 38.409 7.867 45.127C7.867 51.655 8.367 58.12 9.365 64.331C9.74 66.803 10.239 69.211 10.801 71.556C11.8 75.739 15.234 78.972 19.479 79.479C21.289 79.732 23.662 79.986 26.659 80.239C48.699 82.458 70.925 82.394 92.714 80.049C94.774 79.859 96.648 79.606 98.208 79.415C102.392 78.908 105.888 75.676 106.887 71.493C107.449 69.148 107.886 66.739 108.26 64.331C109.26 58.12 109.759 51.591 109.759 45.063C109.696 38.472 109.197 31.88 108.135 25.479V25.479Z"
                stroke="#F1EEEB"
                strokeWidth="1.5"
                className="border"
                data-v-77a80b45=""
                data-svg-origin="7.867000102996826 7.922999858856201"
                transform="matrix(1,0,0,1,0,0)"
                style={{ transformOrigin: '0px 0px', strokeDasharray: '350, 620px' }}
            ></path>
            <path
                d="M83.037 43.923C85.222 42.908 87.033 40.88 87.033 37.775C87.033 34.542 84.411 31.88 81.226 31.88H28.095V36.444H42.268V57.993H48.012V36.443H58.5V57.993H82.35C86.22 57.993 89.404 54.761 89.404 50.831C89.404 47.155 86.594 44.239 83.036 43.923H83.037ZM64.182 36.507H78.417C80.165 36.507 81.102 37.521 81.102 38.979C81.102 40.817 78.667 41.641 77.043 41.894C75.483 42.148 65.243 44.049 64.244 44.239H64.182V36.507ZM78.916 53.367H64.182V48.803L77.48 46.14C81.601 45.253 83.599 47.28 83.599 49.626C83.599 52.288 81.726 53.366 78.916 53.366V53.367Z"
                fill="#F1EEEB"
                data-v-77a80b45=""
            ></path>
        </svg>
    );
};

TresBienSVG.defaultProps = {
    width: '118',
    height: '90',
    viewBox: '0 0 118 90',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
};
