import { useEffect, useState } from 'react';
import { useDebounce } from 'hooks';
import { Constants, blink, gradientBackground } from 'SharedStyles.styles';
import styled from 'styled-components/macro';

type Props = {
    isVisible: boolean;
};

export const LoadScreen: React.FC<Props> = ({ isVisible }) => {
    const [isSafeVisible, setIsSafeVisible] = useState(isVisible);
    const [inTransition, setInTransition] = useState(false);
    const [isInitDone, setIsInitDone] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const debounce = useDebounce();
    const debounce2 = useDebounce();

    useEffect(() => {
        if (isInitDone) {
            debounce(() => setIsSafeVisible(isVisible), 200);
        } else {
            if (isVisible) setIsInitDone(true);
            setIsSafeVisible(isVisible);
        }
    }, [isVisible]);

    useEffect(() => {
        setOpacity(isSafeVisible ? 1 : 0);
        if (isInitDone) {
            setInTransition(true);
            debounce2(() => setInTransition(false), 300);
        }
    }, [isSafeVisible]);

    return (
        <>
            {(isSafeVisible || inTransition) && (
                <Container opacity={opacity}>
                    <Text>LOADING</Text>
                </Container>
            )}
            {isSafeVisible && <PointerBlocker />}
        </>
    );
};

const PointerBlocker: React.FC = () => {
    useEffect(() => {
        const html = document.documentElement as HTMLHtmlElement;
        html.style.overflow = 'hidden';
        html.style.pointerEvents = 'none';
        return () => {
            html.style.overflow = '';
            html.style.pointerEvents = '';
        };
    }, []);

    return <></>;
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div<{ opacity?: number }>`
    ${gradientBackground}
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    opacity: ${({ opacity }) => opacity};
    z-index: ${Constants.maxZLayer};
`;

const Text = styled.div`
    font-size: 3vh;
    animation: ${blink} infinite 2s;
    user-select: none;
`;
