import styled from 'styled-components/macro';
import { LogoSVG } from '../components/svg';
import { Color, spinner } from 'SharedStyles.styles';
import { Link } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
    return (
        <Container>
            <Logo>
                <LogoSVG />
            </Logo>
            <Text>NOT FOUND</Text>
            <BackLink to={'/'}>BACK TO START PAGE</BackLink>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: 100svh;
`;

const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${spinner} infinite 5s linear;
    margin-bottom: 50px;

    .logo__image {
        background-size: 292px;
        height: 100px;
        width: 300px;
    }
`;

const Text = styled.div`
    font-size: 18px;
    margin: 10px 0;
    text-align: center;
    padding: 0 40px;
`;

const BackLink = styled(Link)`
    color: ${Color.white};
    border-bottom: 1px solid ${Color.white};
    transition: color 0.3s, border-bottom 0.3s;

    &:hover {
        color: ${Color.secondary};
        border-bottom: 1px solid ${Color.secondary};
    }
`;
