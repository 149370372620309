import { Color } from 'SharedStyles.styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

type Props = {
    position: 'right' | 'left' | 'center';
};

const POSITION_MAP = {
    right: 100,
    center: 0,
    left: -100,
};

export const MagicCurtain: React.FC<Props> = ({ position }) => {
    const [x, setX] = useState(POSITION_MAP[position]);
    useEffect(() => {
        setX(POSITION_MAP[position]);
    }, [position]);
    return <Curtain translateX={x} />;
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Curtain = styled.div<{ translateX: number }>`
    top: 0;
    background-color: ${Color.black};
    position: fixed;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    transition: transform 0.3s;
    transform: translateX(${({ translateX }) => translateX}vw);
`;
