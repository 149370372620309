import React, { createContext, useEffect, useRef, useState } from 'react';

type WindowContextType = {
    scrollTop: number;
    scrollDir: 'up' | 'down' | undefined;
    windowWidth: number;
    windowHeight: number;
};

const DEFAULT: WindowContextType = {
    scrollTop: 0,
    scrollDir: undefined,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
};

export const WindowContext = createContext<WindowContextType>(DEFAULT);

interface WindowContextProviderProps {
    children: React.ReactNode;
}

export const WindowContextProvider = (props: WindowContextProviderProps) => {
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollDir, setScrollDir] = useState<'up' | 'down'>();
    const [size, setSize] = useState({ windowWidth: DEFAULT.windowWidth, windowHeight: DEFAULT.windowHeight });
    const lastScrollTop = useRef(0);

    const handleScroll = (e: Event) => {
        const elem = (e as any).target.scrollingElement;
        const top = elem.scrollTop;
        const diff = top - lastScrollTop.current;
        if (diff > 0) setScrollDir('down');
        if (diff < 0) setScrollDir('up');
        lastScrollTop.current = top;
        setScrollTop(top);
    };

    const handleResize = () => {
        setSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <WindowContext.Provider value={{ scrollTop, scrollDir, ...size }}>{props.children}</WindowContext.Provider>;
};
