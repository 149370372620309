import { useState } from 'react';
import { useInterval } from 'hooks';
import styled from 'styled-components/macro';

type Props = {
    speed: number;
    children: React.ReactNode;
};

export const XSpinner: React.FC<Props> = ({ speed, children }) => {
    const [animate, setAnimate] = useState(false);
    const [turnsRight, setTurnsRight] = useState(false);

    useInterval(
        () => {
            setTurnsRight((turns) => !turns);
        },
        animate ? speed : null
    );

    const handleMouseOver = () => {
        setTurnsRight((turns) => !turns);
        setAnimate(true);
    };

    return (
        <Container turnsRight={turnsRight} spinSpeed={speed} onMouseEnter={handleMouseOver} onMouseLeave={() => setAnimate(false)}>
            {children}
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div<{ spinSpeed: number; turnsRight: boolean }>`
    height: 100%;
    width: 100%;
    > * {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        @media (hover: hover) {
            transition: transform ${({ spinSpeed }) => spinSpeed || 6000}ms linear;
            transform: rotate3d(0, 1, 0, ${({ turnsRight }) => (turnsRight ? 359 : 0)}deg);
        }
    }
`;
