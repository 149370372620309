import styled, { css } from 'styled-components/macro';
import { useEffect, useMemo, useState } from 'react';
import { blink } from 'SharedStyles.styles';

type Props = {
    lines: string[];
    pause?: boolean;
    hideCursor?: boolean;
    startWithLine?: boolean;
    writeSpeed?: number;
    deleteSpeed?: number;
    thinkTime?: number;
    onCompleted?: () => void;
};

type WritingMode = 'rethinking' | 'writing' | 'deleting' | 'done';

export const UnconvincedWriter: React.FC<Props> = ({
    lines,
    startWithLine,
    writeSpeed = 20,
    deleteSpeed = 10,
    thinkTime = 800,
    hideCursor = false,
    pause,
    onCompleted,
}) => {
    const [mode, setMode] = useState<WritingMode>(startWithLine ? 'deleting' : 'writing');
    const [displayedText, setDisplayedText] = useState(startWithLine ? lines[0] : '');
    const [lineIndex, setLineIndex] = useState(0);

    const hasNext = useMemo(() => lineIndex < lines.length - 1, [lineIndex, lines]);

    const handleWriting = () => {
        if (displayedText === lines[lineIndex]) {
            setMode(hasNext ? 'rethinking' : 'done');
        } else {
            setTimeout(
                () =>
                    setDisplayedText((prev) => {
                        return lines[lineIndex].substring(0, prev.length + 1);
                    }),
                writeSpeed
            );
        }
    };

    const handleDeleting = () => {
        if (displayedText === '') {
            setLineIndex((prev) => Math.min(prev + 1, lines.length - 1));
            setMode('writing');
        } else {
            setTimeout(
                () =>
                    setDisplayedText((prev) => {
                        return lines[lineIndex].substring(0, prev.length - 1);
                    }),
                deleteSpeed
            );
        }
    };

    const rethink = () => {
        setTimeout(() => setMode('deleting'), thinkTime);
    };

    useEffect(() => {
        if (pause) return;
        switch (mode) {
            case 'writing':
                handleWriting();
                break;
            case 'deleting':
                handleDeleting();
                break;
            case 'done':
                onCompleted && onCompleted();
                break;
            case 'rethinking':
                rethink();
        }
    }, [pause, mode, displayedText]);

    return (
        <span>
            {displayedText}
            {mode !== 'done' && !hideCursor && <Cursor isBlinking={!!pause || mode === 'rethinking'}>|</Cursor>}
        </span>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Cursor = styled.span<{ isBlinking?: boolean }>`
    animation: ${({ isBlinking }) =>
        isBlinking
            ? css`
                  ${blink} infinite 1s cubic-bezier(1, 0, 0, 1)
              `
            : ''};
`;
