import { useMemo } from 'react';
import { useWindow } from 'hooks';
import styled from 'styled-components/macro';

type Props = {
    url: string;
    sizeFactor: number;
    mute?: boolean;
    controls?: boolean;
    loop?: boolean;
    autoplay?: boolean;
};

const makeNumber = (v?: boolean) => {
    return !!v ? 1 : 0;
};

export const YouTubeVideo: React.FC<Props> = ({ url, mute, sizeFactor, controls, loop, autoplay }) => {
    const { windowWidth } = useWindow();
    const fullHeight = 315 * (windowWidth / 560);
    const youtubeId = useMemo(() => {
        if (url) {
            const splittedLink = url.includes('?v=') ? url.split('?v=') : url.split('/');
            return splittedLink[splittedLink.length - 1];
        }
    }, [url]);
    const ctrl = useMemo(() => makeNumber(controls), [controls]);
    const lp = useMemo(() => makeNumber(loop), [loop]);
    const auto = useMemo(() => makeNumber(autoplay), [autoplay]);
    const m = useMemo(() => makeNumber(mute), [mute]);

    return (
        <Container>
            <Frame width={windowWidth * sizeFactor} height={fullHeight * sizeFactor} src={`https://www.youtube.com/embed/${youtubeId}?controls=${ctrl}&loop=${lp}&autoplay=${auto}&mute=${m}`} />
        </Container>
    );
};

YouTubeVideo.defaultProps = {
    sizeFactor: 1,
    mute: true,
    controls: false,
    loop: true,
    autoplay: true,
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Frame = styled.iframe`
    border: none;
`;
