import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useMemo, useState } from 'react';
import { CMSContext } from '../contexts/CMSContext';
import { CaseSection, MagicCurtain, YouTubeVideo } from 'components';

export const CasePage: React.FC = () => {
    const { caseId } = useParams();
    const { cases } = useContext(CMSContext);
    const [curtain, setCurtain] = useState<'left' | 'right' | 'center'>('right');
    const navigate = useNavigate();
    const { curr, prev, next } = useMemo(() => {
        const sortedCases = cases.sort((a: any, b: any) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);
            return aDate.getTime() - bDate.getTime();
        });
        const index = sortedCases.findIndex((c) => c.slug + '' === caseId);
        return {
            curr: index !== -1 ? sortedCases[index] : undefined,
            prev: index >= 1 ? sortedCases[index - 1] : undefined,
            next: index <= sortedCases.length - 2 ? sortedCases[index + 1] : undefined,
        };
    }, [cases, caseId]);

    const handleClick = (caseData: any, curtainDir: 'left' | 'right') => {
        setCurtain('center');
        if (caseData) setTimeout(() => navigate(`/work/${caseData.slug}`), 300);
        setTimeout(() => setCurtain(curtainDir), 300 + 400);
    };

    return (
        <Container>
            {curr && (
                <>
                    <CaseSection
                        imageData={{
                            image: curr.thumbnail,
                            caption: curr.description,
                            layoutType: '1',
                        }}
                    />
                    {Object.values(curr.images)
                        .filter((c: any) => !!c.image)
                        .map((image: any, i: number) => (
                            <CaseSection key={i} imageData={image} />
                        ))}
                    {curr.youtube && (
                        <Video>
                            <YouTubeVideo url={curr.youtube} sizeFactor={0.8} />
                        </Video>
                    )}
                </>
            )}
            <CTAs>
                <Button onClick={() => handleClick(prev, 'left')} isRight>
                    {prev !== undefined && <Text>{'ZURÜCK'}</Text>}
                </Button>
                <Button onClick={() => handleClick(next, 'right')}>{next !== undefined && <Text>{'WEITER'}</Text>}</Button>
            </CTAs>
            <MagicCurtain position={curtain} />
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Video = styled.section`
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CTAs = styled.div`
    display: flex;
    width: 100vw;
    margin-bottom: 8vh;
    gap: 4vw;
    justify-content: center;
    align-items: center;
`;

const Button = styled.a<{ isRight?: boolean }>`
    flex: 1 1 50%;
    color: white;
    display: flex;
    justify-content: ${({ isRight }) => (isRight ? 'flex-end' : 'flex-start')};
    align-items: center;
    padding: 10px;
`;

const Text = styled.div`
    font-size: 2vh;
    cursor: pointer;
    transition: transform 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;
