import styled from 'styled-components/macro';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindow } from 'hooks';
import { Color, rotate } from 'SharedStyles.styles';
import { SloganSVG } from './svg';
import { LogoSVG } from './svg/LogoSVG';

export const Planet: React.FC = () => {
    const { windowHeight } = useWindow();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const top = ref.current ? ref.current.getBoundingClientRect().top : 1;
    return (
        <Container ref={ref}>
            <Link isVisible={0 >= top - windowHeight / 4}>
                <Saturn onClick={() => navigate('./work')}>
                    <Slogan>
                        <SloganSVG />
                    </Slogan>
                </Saturn>
                <Content>
                    <Text>MEHR VON</Text>
                    <Logo>
                        <LogoSVG />
                    </Logo>
                </Content>
            </Link>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`;

const Link = styled.div<{ isVisible?: boolean }>`
    transform: ${({ isVisible }) => (isVisible ? 'translateY(-3vh)' : '')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: transform 0.3s, opacity 0.3s;
    border-radius: 10px;
    color: ${Color.black};
`;

const Slogan = styled.div`
    transform-style: preserve-3d;
    width: 180%;
    height: 180%;
    flex-shrink: 0;
    transform: rotate3d(1, 0, 0, 65deg);
    transition: transform 0.5s, height 0.5s, width 0.5s;
    svg {
        animation: ${rotate} infinite 70s linear;
    }
`;

const Saturn = styled.div`
    justify-content: center;
    border-radius: 100%;
    background: radial-gradient(${Color.primary2}, 80%, ${Color.black});
    height: 35vh;
    width: 35vh;
    display: flex;
    align-items: center;
    transform-style: preserve-3d;
    transition: height 0.3s, width 0.3s;
    transform: translate3d(0, 0, -1000px);
    position: relative;
    cursor: pointer;

    :hover {
        height: 45vh;
        width: 45vh;
    }
`;

const Content = styled.div`
    position: absolute;
    top: -5%;
    height: 100%;
    width: 100%;
    gap: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
`;

const Logo = styled.div`
    width: 70%;
`;

const Text = styled.div`
    font-size: 2vh;
    user-select: none;
`;
