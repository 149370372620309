import ReactDOM from 'react-dom/client';
import './index.css';
import { CMSContextProvider, LoadContextProvider, WindowContextProvider } from './contexts';
import { WebsiteRouter } from './WebsiteRouter';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <LoadContextProvider>
        <CMSContextProvider>
            <WindowContextProvider>
                <WebsiteRouter />
            </WindowContextProvider>
        </CMSContextProvider>
    </LoadContextProvider>
);
