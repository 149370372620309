import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BasePage, StartPage, ContactPage, WorkPage, CasePage, MaintenancePage, NotFoundPage } from './pages';

export const WebsiteRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {process.env.REACT_APP_MAINTENANCE === '0' ? (
                    <>
                        <Route path="/" element={<BasePage />}>
                            <Route index element={<StartPage />} />
                            <Route path="work" element={<WorkPage />} />
                            <Route path="/work/:caseId" element={<CasePage />} />
                            <Route path="contact" element={<ContactPage />} />
                        </Route>
                        <Route path="/*" element={<NotFoundPage />} />
                    </>
                ) : (
                    <Route path="*" element={<MaintenancePage />} />
                )}
            </Routes>
        </BrowserRouter>
    );
};
