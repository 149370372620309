import styled from 'styled-components/macro';
import { useCMS } from 'hooks';
import { Category } from 'components';
import { useMemo } from 'react';

const ORDER = [247, 245, 248, 246];

export const WorkPage: React.FC = () => {
    const { categories } = useCMS();
    const sortedCategories = useMemo(
        () =>
            categories.sort((a, b) => {
                return ORDER.indexOf(a.id) - ORDER.indexOf(b.id);
            }),
        [categories]
    );
    return (
        <Container>
            {sortedCategories.map((category, i) => (
                <Category key={i} category={category} />
            ))}
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4vh 8vw 8vh;
`;
