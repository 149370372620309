import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const RagucciSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path
                d="M51.78,78.19h4.76l9.38,19.46l15.88-0.83L70.67,75.65c4.52-2.23,9.37-7.09,9.37-14.81c0-9.32-6.86-18.23-19.62-18.23H37.64
	v56.53l14.13-0.74V78.19z M51.78,55.1h8c3.88,0,6.14,2.79,6.14,5.73s-2.26,5.73-6.14,5.73h-8V55.1z"
            />
            <path
                d="M127.12,82.57c0-7.96-4.77-10.91-7.11-12.34c1.78-1.19,6.3-4.78,6.3-11.23c0-9.79-6.38-16.4-18.33-16.4H84.16v54.09l38.15-2
	C125.32,91.87,127.12,87.81,127.12,82.57z M98.29,55.1h8.8c3.88,0,5.09,2.39,5.09,4.62s-1.21,4.62-5.09,4.62h-8.8V55.1z
	 M107.82,86.79h-9.53v-9.95h9.53c3.88,0,5.17,2.55,5.17,4.94C112.99,84.17,111.7,86.79,107.82,86.79z"
            />
            <polygon
                points="144.81,86.16 150.45,77.45 159.18,92.76 174.96,91.93 158.67,64.82 173.12,42.6 157.45,42.6 144.81,62.67 
	144.81,42.6 130.84,42.6 130.84,94.25 144.81,93.51 "
            />
        </svg>
    );
};

RagucciSVG.defaultProps = {
    fill: 'currentColor',
    height: '960',
    width: '960',
    xmlns: 'http://www.w3.org/2000/svg',

    version: '1.1',
    id: 'Ebene_1_1_',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
    x: '0px',
    y: '0px',
    viewBox: '0 0 212.6 141.73',
    // style:"enable-background:new 0 0 212.6 141.73;",
    xmlSpace: 'preserve',
};
