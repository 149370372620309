import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const PhoneSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg {...props}>
            <path d="M796 959q-127 0-252-62T319 735.5q-100-99.5-162-225T95 259q0-29 20-49t49-20h140q31 0 50.5 16t26.5 47l27 110q5 27-1 46.5T385 443l-105 95q50 79 108.5 136.5T519 772l100-101q18-18 38.5-24.5t45.5-.5l98 24q31 8 47.5 28t16.5 51v140q0 30-20 50t-49 20Z" />
        </svg>
    );
};

PhoneSVG.defaultProps = {
    fill: 'currentColor',
    viewBox: '0 96 960 960',
    height: '960',
    width: '960',
    xmlns: 'http://www.w3.org/2000/svg',
};
