import React, { createContext, useEffect, useState } from 'react';

type Props = {
    children: React.ReactNode;
};

type LoadContextType = {
    isLoading: boolean;
    subscribers: Set<string>;
    bgLoadingMembers: Set<string>;
    subscribe: (
        identifier: string,
        options?: {
            isNonBlocking?: boolean;
            remember?: boolean;
        }
    ) => void;
    unsubscribe: (identifier: string) => void;
};

export const LoadContext = createContext<LoadContextType>({
    isLoading: true,
    subscribers: new Set(),
    bgLoadingMembers: new Set(),
    subscribe: (_) => {},
    unsubscribe: (_) => {},
});

export const LoadContextProvider: React.FC<Props> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [subscribers, setSubscribers] = useState<Set<string>>(() => new Set());
    const [bgLoadingMembers, setBgLoadingMembers] = useState<Set<string>>(() => new Set());
    const [remembered, setRemembered] = useState<Set<string>>(() => new Set());
    useEffect(() => {
        setIsLoading(subscribers.size > 0);
    }, [subscribers]);

    const subscribe = (
        identifier: string,
        options?: {
            isNonBlocking?: boolean;
            remember?: boolean;
        }
    ) => {
        if (options && options.remember) setRemembered((prev) => new Set(prev).add(identifier));
        if (remembered.has(identifier)) return;
        if (options && options.isNonBlocking) {
            setBgLoadingMembers((prev) => new Set(prev).add(identifier));
        } else {
            setSubscribers((prev) => new Set(prev).add(identifier));
        }
    };

    const unsubscribe = (identifier: string) => {
        setSubscribers((prev) => {
            const next = new Set(prev);
            next.delete(identifier);
            return next;
        });
        setBgLoadingMembers((prev) => {
            const next = new Set(prev);
            next.delete(identifier);
            return next;
        });
    };

    return (
        <LoadContext.Provider value={{ isLoading: isLoading, subscribers, bgLoadingMembers, subscribe, unsubscribe }}>
            {children}
        </LoadContext.Provider>
    );
};
