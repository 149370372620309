import { css, keyframes } from 'styled-components';

// -----------------------------------------------------------------------------
//                                  CONSTANTS
// -----------------------------------------------------------------------------

export const Color = {
    white: 'white',

    grey: 'grey',
    grey2: 'darkslategrey',
    grey3: 'dimgrey',

    black: 'black',
    black2: '#202020',

    primary: 'bisque',
    primary2: 'navajowhite',

    secondary: 'lightblue',

    shadow: '#000000AA',
};

export const Constants = {
    maxZLayer: 999,
    mobileWidth: '630px',
};

// -----------------------------------------------------------------------------
//                                   MIXINS
// -----------------------------------------------------------------------------

export const gradientBackground = css`
    background: linear-gradient(90deg, ${Color.black2}, 60vw, ${Color.black});
`;

// -----------------------------------------------------------------------------
//                                 ANIMATIONS
// -----------------------------------------------------------------------------

export const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

export const blink = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
`;

export const spinner = keyframes`
    0% {
        transform: rotate3d(0, 0, 0, 0deg);
    }

    50% {
        transform: rotate3d(0, 1, 0, 180deg);
    }
`;
