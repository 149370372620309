import { useContext, useLayoutEffect } from 'react';
import { LoadContext } from 'contexts';

export function useLoadState(
    identifier?: string,
    options?: {
        isNonBlocking?: boolean;
        remember?: boolean;
    }
) {
    const context = useContext(LoadContext);
    useLayoutEffect(() => {
        identifier && context.subscribe(identifier, options);
    }, []);
    const unsubscribe = () => {
        identifier && context.unsubscribe(identifier);
    };
    return {
        isLoadingGlobal: context.isLoading,
        isLoading: identifier ? context.subscribers.has(identifier) || context.bgLoadingMembers.has(identifier) : undefined,
        unsubscribe,
    };
}
