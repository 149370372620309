import styled, { css } from 'styled-components/macro';
import { useCMS, useWindow } from 'hooks';
import { Preview } from 'components';
import { useNavigate } from 'react-router-dom';
import { Color, Constants } from 'SharedStyles.styles';
import { useEffect, useRef, useState } from 'react';

type Props = {
    category: any;
};

export const Category: React.FC<Props> = ({ category }) => {
    const { cases } = useCMS();
    const navigate = useNavigate();
    const { isMobile, windowHeight } = useWindow();
    const [shadow, setShadow] = useState({ top: false, bottom: false });
    const ref = useRef<HTMLDivElement>(null);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
        const elem = e.target as HTMLDivElement;
        placeShadow(elem);
    };

    const placeShadow = (elem: HTMLDivElement) => {
        let top = false,
            bottom = false;
        if (elem.scrollHeight !== elem.offsetHeight) {
            top = elem.scrollTop > 0;
            bottom = elem.scrollTop + elem.offsetHeight < elem.scrollHeight;
        }
        setShadow({ top, bottom });
    };

    useEffect(() => {
        if (ref.current) placeShadow(ref.current);
    }, [ref.current]);

    return (
        <Container>
            <Title>{(category.title as string).toUpperCase()}</Title>
            <Description ref={ref} onScroll={handleScroll} shadow={shadow}>
                <DescriptionText>{category.description}</DescriptionText>
            </Description>
            <Cases>
                {cases
                    .filter((c) => c.category.ID === category.id)
                    .map((caseData, i) => (
                        <Case onClick={() => navigate(`/work/${caseData.slug}`)} key={i}>
                            <Preview caseData={caseData} playMode={isMobile ? 'onScrollTop' : 'onHover'} playZoneOffset={windowHeight * 1.5} />
                            <CaseLabel>
                                <CaseTitle>{caseData.title}</CaseTitle>
                                <Client>{caseData.client}</Client>
                            </CaseLabel>
                        </Case>
                    ))}
            </Cases>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 8vh;
`;

const Title = styled.h1``;

const getShadow = (shadow: { top: boolean; bottom: boolean }) => {
    const color = Color.shadow;
    const a = 28;
    const b = 18;
    let top = '',
        bottom = '';
    if (shadow.top) top = `${color} 0 ${a}px ${b}px -${a}px inset`;
    if (shadow.bottom) bottom = `${color} 0 -${a}px ${b}px -${a}px inset`;
    return css`
        box-shadow: ${[top, bottom].filter((v) => v).join(',')};
    `;
};
const Description = styled.div<{ shadow?: { top: boolean; bottom: boolean } }>`
    max-height: 100px;
    overflow: scroll;
    position: relative;
    white-space: pre-wrap;
    transition: box-shadow 0.3s;
    ${({ shadow }) => (shadow ? getShadow(shadow) : '')};
    margin-bottom: 2vh;
`;

const DescriptionText = styled.div`
    box-sizing: border-box;
    line-height: 22px;
    padding: 10px 0;
`;

const Cases = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5vw;
`;

const CaseLabel = styled.div`
    position: absolute;
    bottom: 0;
    padding: 5px;
    box-sizing: border-box;
    filter: drop-shadow(${Color.black} -1px 2px 2px);
    color: ${Color.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;

    @media (hover: none) {
        opacity: 1;
    }
`;

const Case = styled.a`
    cursor: pointer;
    height: 180px;
    width: 320px;
    position: relative;
    user-select: none;

    @media (max-width: ${Constants.mobileWidth}) {
        height: 25vh;
        width: 100%;
    }

    transition: transform 0.3s;
    @media (hover: hover) {
        :hover {
            transform: scale(1.05);

            ${CaseLabel} {
                opacity: 1;
            }
        }
    }
`;

const CaseTitle = styled.h2`
    flex: 1;
    margin: 0;
    padding: 0;
`;

const Client = styled.h3`
    flex: 1;
    margin: 0;
    padding: 0;
    color: ${Color.primary2};
`;
