import styled from 'styled-components/macro';
import { Contact } from 'components';

export const ContactPage: React.FC = () => {
    return (
        <Container>
            <Contact />
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    flex: 1;
    display: flex;
    justify-items: center;
    align-items: center;
`;
