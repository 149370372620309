import styled from 'styled-components/macro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInterval, useWindow } from 'hooks';
import { Img } from './Img';

type Props = {
    caseData?: {
        title: string;
        client: string;
        thumbnail: string;
        images: {
            image: string;
        }[];
    };
    playMode?: 'onHover' | 'onScrollTop';
    playZoneOffset?: number;
    onIsPlaying?: (isPlaying: boolean) => void;
    borderRadius?: number;
};

export const Preview: React.FC<Props> = ({ caseData, playMode, onIsPlaying, playZoneOffset, borderRadius }) => {
    const [activeFile, setActiveFile] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const files = useMemo(
        () =>
            caseData
                ? [
                      caseData.thumbnail,
                      ...Object.values(caseData.images)
                          .map((img) => img.image)
                          .filter((img) => img),
                  ]
                : [],
        [caseData]
    );

    const thumbnail = files[0];
    const previewFiles = files.slice(1);

    const [pending, setPending] = useState(previewFiles.length - 1);

    useInterval(
        () => {
            setActiveFile((freshIndex) => (freshIndex + 1 >= files.length ? 0 : freshIndex + 1));
        },
        isPlaying && pending <= 0 ? 2000 : null
    );

    const { scrollTop } = useWindow();
    const inPlayZone = useMemo(() => {
        if (ref.current) {
            const offset = playZoneOffset || 0;
            const top = ref.current.getBoundingClientRect().top - offset;
            const height = ref.current.offsetHeight;
            return top < 0 && top + height < scrollTop;
        }
        return false;
    }, [ref.current, scrollTop]);

    useEffect(() => {
        if (playMode === 'onScrollTop') setIsPlaying(inPlayZone);
    }, [inPlayZone]);

    useEffect(() => {
        onIsPlaying && onIsPlaying(isPlaying);
    }, [isPlaying]);

    const getStyle = (index: number) => {
        if (isPlaying) {
            return index !== activeFile ? { opacity: 0 } : { opacity: 1 };
        }
        return index !== 0 ? { opacity: 0 } : { opacity: 1 };
    };

    const handleEnter = () => {
        if (playMode === 'onHover') setIsPlaying(true);
    };

    const handleLeave = () => {
        if (playMode === 'onHover') setIsPlaying(false);
    };

    const handleDone = () => {
        setPending((prev) => prev - 1);
    };

    return (
        <Container ref={ref} onMouseEnter={handleEnter} onMouseLeave={handleLeave} borderRadius={borderRadius}>
            <Img globalId={thumbnail} src={thumbnail} style={getStyle(0)} />
            {!!playMode &&
                inPlayZone &&
                previewFiles.map((file, i) => (
                    <Img globalId={file} loadInBackground onLoad={() => handleDone()} key={i} src={file} style={getStyle(i + 1)} />
                ))}
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

export const Container = styled.div<{ borderRadius?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: ${({ borderRadius }) => borderRadius + 'px'};

    img {
        position: absolute;
        transition: display 0.3s, opacity 0.3s;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;
