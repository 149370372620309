import styled from 'styled-components/macro';
import { LogoSVG } from '../components/svg';
import { Color, spinner } from 'SharedStyles.styles';

export const MaintenancePage: React.FC = () => {
    return (
        <Container>
            <Logo>
                <LogoSVG />
            </Logo>
            <Text>We're currently working on this website and will show you who we are and some of our cases very soon.</Text>
            <Text>
                For any enquiries feel free to <br />
                <Email href="mailto:contact@diay21.com">reach out to us</Email>.
            </Text>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: 100svh;
`;

const Logo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${spinner} infinite 5s linear;
    margin-bottom: 50px;

    .logo__image {
        background-size: 292px;
        height: 100px;
        width: 300px;
    }
`;

const Text = styled.div`
    font-size: 18px;
    margin: 10px 0;
    text-align: center;
    padding: 0 40px;
`;

const Email = styled.a`
    color: ${Color.white};
    border-bottom: 1px solid ${Color.white};
    transition: color 0.3s, border-bottom 0.3s;

    &:hover {
        color: ${Color.secondary};
        border-bottom: 1px solid ${Color.secondary};
    }
`;
