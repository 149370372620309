import styled from 'styled-components/macro';
import { ClientLogo } from './ClientLogo';
import { RagucciSVG, TresBienSVG, UniversalMusicGroupSVG } from './svg';
import { XSpinner } from './XSpinner';

export const Clients: React.FC = () => {
    const spinSpeed = 4500;

    return (
        <Container>
            <Logo>
                <XSpinner speed={spinSpeed}>
                    <UniversalMusicGroupSVG />
                </XSpinner>
            </Logo>
            <Logo>
                <XSpinner speed={spinSpeed}>
                    <TresBienSVG />
                </XSpinner>
            </Logo>
            <Logo>
                <XSpinner speed={spinSpeed}>
                    <ClientLogo client="trailblazer" />
                </XSpinner>
            </Logo>
            <Logo>
                <XSpinner speed={spinSpeed}>
                    <RagucciSVG />
                </XSpinner>
            </Logo>
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 80vh;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: 2vw;
    position: relative;
`;

const Logo = styled.div`
    width: 40%;
    height: 40%;
    user-select: none;
`;
