import React from 'react';
import styled from 'styled-components/macro';

type Props = {
    children?: React.ReactNode;
    scrollLengthFactor?: number;
    backgroundColor?: string;
    isPseudo?: boolean;
};

export const Section: React.FC<Props> = ({ children, scrollLengthFactor, backgroundColor, isPseudo }) => {
    const height = scrollLengthFactor ? 100 * scrollLengthFactor : 100;

    return (
        <ScrollContainer isPseudo={isPseudo} backgroundColor={backgroundColor} height={height}>
            <div>{children}</div>
        </ScrollContainer>
    );
};

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const ScrollContainer = styled.section<{ backgroundColor?: string; height?: number; isPseudo?: boolean }>`
    height: ${({ height }) => height || 100}vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: sticky;
    top: calc(100vh - ${({ height }) => height || 100}vh);
    pointer-events: ${({ isPseudo }) => (isPseudo ? 'none' : '')};
`;
