import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const SloganSVG: React.FC<Props> = ({ ...props }) => {
    return (
        <svg style={{ userSelect: 'none' }} {...props}>
            <text transform="translate(92.94 280.02) rotate(-77.88)">
                <tspan x="0" y="0">
                    N
                </tspan>
            </text>
            <text transform="translate(108.25 206.38) rotate(-53.61)">
                <tspan x="0" y="0">
                    O
                </tspan>
            </text>
            <text transform="translate(188.76 118.8) rotate(-19.66)">
                <tspan x="0" y="0">
                    L
                </tspan>
            </text>
            <text transform="translate(257.73 97.54) rotate(-3.13)">
                <tspan x="0" y="0">
                    I
                </tspan>
            </text>
            <text transform="translate(300.7 92.01) rotate(17.89)">
                <tspan x="0" y="0">
                    M
                </tspan>
            </text>
            <text transform="translate(384.69 124.16) rotate(37.99)">
                <tspan x="0" y="0">
                    I
                </tspan>
            </text>
            <text transform="translate(419.76 149.53) rotate(55.39)">
                <tspan x="0" y="0">
                    T
                </tspan>
            </text>
            <text transform="translate(459.84 208.93) rotate(76.86)">
                <tspan x="0" y="0">
                    S
                </tspan>
            </text>
            <text transform="translate(472.05 311.75) rotate(105.87)">
                <tspan x="0" y="0">
                    •
                </tspan>
            </text>
            <text transform="translate(444.48 389.55) rotate(134.45)">
                <tspan x="0" y="0">
                    A
                </tspan>
            </text>
            <text transform="translate(389.69 443.82) rotate(156.86)">
                <tspan x="0" y="0">
                    L
                </tspan>
            </text>
            <text transform="translate(323.45 471.52) rotate(178.73)">
                <tspan x="0" y="0">
                    L
                </tspan>
            </text>
            <text transform="translate(218.5 461.55) rotate(-153.68)">
                <tspan x="0" y="0">
                    I
                </tspan>
            </text>
            <text transform="translate(178.93 444.13) rotate(-135.73)">
                <tspan x="0" y="0">
                    N
                </tspan>
            </text>
            <text transform="translate(110.3 359.84) rotate(-105.83)">
                <tspan x="0" y="0">
                    •
                </tspan>
            </text>
        </svg>
    );
};

SloganSVG.defaultProps = {
    height: '100%',
    width: '100%',
    fill: '#ffffff',
    fontFamily: 'Didot',
    fontSize: '100',
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 568.69 568.68',
};
