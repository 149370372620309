import { useEffect, useState } from 'react';
import { Color } from 'SharedStyles.styles';
import styled from 'styled-components/macro';

export const Stars: React.FC = () => {
    const [coordinates, setCoordinates] = useState<{ x: number; y: number; size: number }[]>([]);

    useEffect(() => {
        setCoordinates(
            new Array(50).fill(0).map((_) => {
                return {
                    x: getRandomInt(0, 100),
                    y: getRandomInt(0, 100),
                    size: takeOneOutOfTwo(5, 8),
                };
            })
        );
    }, []);

    return (
        <Container>
            {coordinates.map((o, i) => (
                <Circle
                    key={i}
                    style={{
                        left: `${o.x}%`,
                        top: `${o.y}%`,
                        height: `${o.size}px`,
                        width: `${o.size}px`,
                    }}
                />
            ))}
        </Container>
    );
};

/* -------------------------------------------------------------------------- */
/*                                    UTILS                                   */
/* -------------------------------------------------------------------------- */

function getRandomInt(min: number, max: number) {
    const random = Math.random();
    const range = max - min + 1;
    const offset = Math.floor(random * range);
    const result = min + offset;
    return result;
}

function takeOneOutOfTwo(num1: number, num2: number): number {
    const random = Math.random();
    if (random < 0.5) {
        return num1;
    } else {
        return num2;
    }
}

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Circle = styled.div`
    position: absolute;
    border-radius: 100%;
    background-color: ${Color.white};
    opacity: 0.5;
`;
